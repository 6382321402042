import { AlignmentType, Document, Paragraph, TextRun, Media, Header, Footer, Table, TableRow, TableCell, WidthType, PageNumber, ShadingType, PageBreak, BorderStyle } from "docx";
import { logo } from "../logo";

//Moment import
const moment = require('moment')
moment.locale('es-mx')

export class DocumentCreatorMTO_FINS_08_v3 {
    // INIT MTO_FINS_08
    public create_MTO_FINS_08(data): Document {
        const document = new Document();
        const image = Media.addImage(document, logo, 50, 50);

        const listaCalculoCobre = this.getValueList(data.submission, 'lista-calculo-cobre')
        listaCalculoCobre.forEach(element => {
            document.addSection({
                headers: {
                    default: new Header({
                        children: [
                            new Paragraph({ alignment: AlignmentType.CENTER, children: [image], }),
                            new Paragraph({
                                alignment: AlignmentType.CENTER, children: [
                                    new TextRun({
                                        children: [`MTO-FINS-08 | R 01 | 13-Noviembre-2024 | Pág. `, PageNumber.CURRENT],
                                        font: 'Book Antiqua',
                                        size: 20,
                                        bold: true
                                    }),
                                    new TextRun({
                                        font: 'Book Antiqua',
                                        size: 20,
                                        bold: true,
                                        children: [' de ', PageNumber.TOTAL_PAGES]
                                    }),
                                ], spacing: { before: 200, after: 100 }
                            })
                        ]
                    })
                },
                footers: {
                    default: new Footer({
                        children: [
                            new Paragraph({ alignment: AlignmentType.CENTER }),
                            this.createFooter()
                        ]
                    }),
                },
                children: [
                    this.createTableCalculoCobre(data, element),
                ],
                margins: {
                    top: 350,
                    bottom: 500,
                    left: 1000,
                    right: 1000
                }
            })
        })

        return document
    }


    public createFooter(): Table {
        const table = new Table({
            rows: [
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Elaboró:',
                                            font: 'Calibri (Cuerpo)',
                                            size: 22
                                        })
                                    ]
                                })
                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Revisó:',
                                            font: 'Calibri (Cuerpo)',
                                            size: 22
                                        })
                                    ]
                                })
                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Autorizó:',
                                            font: 'Calibri (Cuerpo)',
                                            size: 22
                                        })
                                    ]
                                }),
                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            }
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Director de certificación',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16,
                                            bold: true
                                        })
                                    ]
                                }),

                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            },
                            shading: {
                                fill: '#C0C0C0'
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Responsable de calidad',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16,
                                            bold: true
                                        })
                                    ]
                                }),

                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            },
                            shading: {
                                fill: '#C0C0C0'
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Director general',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16,
                                            bold: true
                                        })
                                    ]
                                }),
                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            },
                            shading: {
                                fill: '#C0C0C0'
                            }
                        })
                    ]
                })
            ],
            margins: {
                left: 70,
                right: 70
            },
            //9638
            columnWidths: [3213, 3213, 3212],
            alignment: AlignmentType.CENTER
        })
        return table
    }

    public createTableCalculoCobre(data, element): Table {
        const table = new Table({
            rows: [
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'FORMATO PARA CÁLCULO DE COBRE',
                                            font: 'Arial',
                                            size: 20,
                                        })
                                    ],
                                    spacing: { before: 100, after: 100 }
                                })
                            ],
                            borders: {
                                bottom: {
                                    style: BorderStyle.NONE,
                                    color: "white",
                                    size: 0
                                },
                            },
                            width: {
                                size: 100,
                                type: WidthType.PERCENTAGE
                            }
                        }),
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `Nombre del operador: ${this.getValue(data.submission, 'nombre_operador')}`,
                                            font: 'Arial',
                                            size: 20,
                                        })
                                    ],
                                    spacing: { before: 100, after: 100 }
                                })
                            ],
                            borders: {
                                top: {
                                    style: BorderStyle.NONE,
                                    color: "white",
                                    size: 0
                                },
                                bottom: {
                                    style: BorderStyle.NONE,
                                    color: "white",
                                    size: 0
                                },
                            },
                            width: {
                                size: 100,
                                type: WidthType.PERCENTAGE
                            }
                        }),
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `Código del operador: ${this.getValue(data.submission, 'codigo_operador')}`,
                                            font: 'Arial',
                                            size: 20,
                                        })
                                    ],
                                    spacing: { before: 100, after: 100 }
                                })
                            ],
                            borders: {
                                top: {
                                    style: BorderStyle.NONE,
                                    color: "white",
                                    size: 0
                                },
                                bottom: {
                                    style: BorderStyle.NONE,
                                    color: "white",
                                    size: 0
                                },
                            },
                            width: {
                                size: 100,
                                type: WidthType.PERCENTAGE
                            }
                        }),
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `Fecha de actualización: ${moment(this.getValue(data.submission, 'fecha')).format('DD/MM/YYYY')}`,
                                            font: 'Arial',
                                            size: 20,
                                        })
                                    ],
                                    spacing: { before: 100, after: 100 }
                                })
                            ],
                            borders: {
                                top: {
                                    style: BorderStyle.NONE,
                                    color: "white",
                                    size: 0
                                },
                            },
                            width: {
                                size: 100,
                                type: WidthType.PERCENTAGE
                            }
                        }),
                    ]
                }),

                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `Parcela/s: ${element.calculo_cobre_parcela}`,
                                            font: 'Arial',
                                            size: 20,
                                        })
                                    ],
                                    spacing: { before: 100, after: 100 }
                                })
                            ],
                            borders: {
                                top: {
                                    style: BorderStyle.NONE,
                                    color: "white",
                                    size: 0
                                },
                                bottom: {
                                    style: BorderStyle.NONE,
                                    color: "white",
                                    size: 0
                                },
                                right: {
                                    style: BorderStyle.NONE,
                                    color: "white",
                                    size: 0
                                },
                            },
                            width: {
                                size: 75,
                                type: WidthType.PERCENTAGE
                            },
                            columnSpan: 4
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Nota: Realizar cálculo para un año',
                                            font: 'Arial',
                                            size: 20,
                                        })
                                    ],
                                    spacing: { before: 100, after: 100 }
                                })
                            ],
                            borders: {
                                top: {
                                    style: BorderStyle.NONE,
                                    color: "white",
                                    size: 0
                                },
                                bottom: {
                                    style: BorderStyle.NONE,
                                    color: "white",
                                    size: 0
                                },
                                left: {
                                    style: BorderStyle.NONE,
                                    color: "white",
                                    size: 0
                                },
                            },
                            width: {
                                size: 25,
                                type: WidthType.PERCENTAGE
                            },
                            columnSpan: 3
                        }),
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `Cultivo: ${element.calculo_cobre_cultivo}`,
                                            font: 'Arial',
                                            size: 20,
                                        })
                                    ],
                                    spacing: { before: 100, after: 100 }
                                })
                            ],
                            borders: {
                                top: {
                                    style: BorderStyle.NONE,
                                    color: "white",
                                    size: 0
                                },
                                right: {
                                    style: BorderStyle.NONE,
                                    color: "white",
                                    size: 0
                                },
                            },
                            width: {
                                size: 75,
                                type: WidthType.PERCENTAGE
                            },
                            columnSpan: 4
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `Período: ${element.calculo_cobre_periodo}`,
                                            font: 'Arial',
                                            size: 20,
                                        })
                                    ],
                                    spacing: { before: 100, after: 100 }
                                })
                            ],
                            borders: {
                                top: {
                                    style: BorderStyle.NONE,
                                    color: "white",
                                    size: 0
                                },
                                left: {
                                    style: BorderStyle.NONE,
                                    color: "white",
                                    size: 0
                                },
                            },
                            width: {
                                size: 25,
                                type: WidthType.PERCENTAGE
                            },
                            columnSpan: 3
                        }),
                    ]
                }),
                
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Kg de cobre aplicado en ciclos previos',
                                            font: 'Arial',
                                            size: 20,
                                        })
                                    ],
                                    spacing: { before: 100, after: 100 }
                                })
                            ],
                            width: {
                                size: 100,
                                type: WidthType.PERCENTAGE
                            }
                        }),
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '1',
                                            font: 'Arial',
                                            size: 20,
                                        })
                                    ],
                                    spacing: { before: 100, after: 100 }
                                })
                            ],
                            borders: {
                                top: {
                                    style: BorderStyle.NONE,
                                    color: "white",
                                    size: 0
                                },
                            },
                            width: {
                                size: 14,
                                type: WidthType.PERCENTAGE
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '2',
                                            font: 'Arial',
                                            size: 20,
                                        })
                                    ],
                                    spacing: { before: 100, after: 100 }
                                })
                            ],
                            borders: {
                                top: {
                                    style: BorderStyle.NONE,
                                    color: "white",
                                    size: 0
                                },
                            },
                            width: {
                                size: 15,
                                type: WidthType.PERCENTAGE
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '3',
                                            font: 'Arial',
                                            size: 20,
                                        })
                                    ],
                                    spacing: { before: 100, after: 100 }
                                })
                            ],
                            borders: {
                                top: {
                                    style: BorderStyle.NONE,
                                    color: "white",
                                    size: 0
                                },
                            },
                            width: {
                                size: 15,
                                type: WidthType.PERCENTAGE
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '4',
                                            font: 'Arial',
                                            size: 20,
                                        })
                                    ],
                                    spacing: { before: 100, after: 100 }
                                })
                            ],
                            borders: {
                                top: {
                                    style: BorderStyle.NONE,
                                    color: "white",
                                    size: 0
                                },
                            },
                            width: {
                                size: 14,
                                type: WidthType.PERCENTAGE
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '5',
                                            font: 'Arial',
                                            size: 20,
                                        })
                                    ],
                                    spacing: { before: 100, after: 100 }
                                })
                            ],
                            borders: {
                                top: {
                                    style: BorderStyle.NONE,
                                    color: "white",
                                    size: 0
                                },
                            },
                            width: {
                                size: 14,
                                type: WidthType.PERCENTAGE
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '6',
                                            font: 'Arial',
                                            size: 20,
                                        })
                                    ],
                                    spacing: { before: 100, after: 100 }
                                })
                            ],
                            borders: {
                                top: {
                                    style: BorderStyle.NONE,
                                    color: "white",
                                    size: 0
                                },
                            },
                            width: {
                                size: 14,
                                type: WidthType.PERCENTAGE
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '7',
                                            font: 'Arial',
                                            size: 20,
                                        })
                                    ],
                                    spacing: { before: 100, after: 100 }
                                })
                            ],
                            borders: {
                                top: {
                                    style: BorderStyle.NONE,
                                    color: "white",
                                    size: 0
                                },
                            },
                            width: {
                                size: 14,
                                type: WidthType.PERCENTAGE
                            }
                        }),
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: `${element.calculo_cobre_ciclo_1}`,
                                            font: 'Arial',
                                            size: 20,
                                        })
                                    ],
                                    spacing: { before: 100, after: 100 }
                                })
                            ],
                            borders: {
                                top: {
                                    style: BorderStyle.NONE,
                                    color: "white",
                                    size: 0
                                },
                            },
                            width: {
                                size: 14,
                                type: WidthType.PERCENTAGE
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: `${element.calculo_cobre_ciclo_2}`,
                                            font: 'Arial',
                                            size: 20,
                                        })
                                    ],
                                    spacing: { before: 100, after: 100 }
                                })
                            ],
                            borders: {
                                top: {
                                    style: BorderStyle.NONE,
                                    color: "white",
                                    size: 0
                                },
                            },
                            width: {
                                size: 15,
                                type: WidthType.PERCENTAGE
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: `${element.calculo_cobre_ciclo_3}`,
                                            font: 'Arial',
                                            size: 20,
                                        })
                                    ],
                                    spacing: { before: 100, after: 100 }
                                })
                            ],
                            borders: {
                                top: {
                                    style: BorderStyle.NONE,
                                    color: "white",
                                    size: 0
                                },
                            },
                            width: {
                                size: 15,
                                type: WidthType.PERCENTAGE
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: `${element.calculo_cobre_ciclo_4}`,
                                            font: 'Arial',
                                            size: 20,
                                        })
                                    ],
                                    spacing: { before: 100, after: 100 }
                                })
                            ],
                            borders: {
                                top: {
                                    style: BorderStyle.NONE,
                                    color: "white",
                                    size: 0
                                },
                            },
                            width: {
                                size: 14,
                                type: WidthType.PERCENTAGE
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: `${element.calculo_cobre_ciclo_5}`,
                                            font: 'Arial',
                                            size: 20,
                                        })
                                    ],
                                    spacing: { before: 100, after: 100 }
                                })
                            ],
                            borders: {
                                top: {
                                    style: BorderStyle.NONE,
                                    color: "white",
                                    size: 0
                                },
                            },
                            width: {
                                size: 14,
                                type: WidthType.PERCENTAGE
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: `${element.calculo_cobre_ciclo_6}`,
                                            font: 'Arial',
                                            size: 20,
                                        })
                                    ],
                                    spacing: { before: 100, after: 100 }
                                })
                            ],
                            borders: {
                                top: {
                                    style: BorderStyle.NONE,
                                    color: "white",
                                    size: 0
                                },
                            },
                            width: {
                                size: 14,
                                type: WidthType.PERCENTAGE
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: `${element.calculo_cobre_ciclo_7}`,
                                            font: 'Arial',
                                            size: 20,
                                        })
                                    ],
                                    spacing: { before: 100, after: 100 }
                                })
                            ],
                            borders: {
                                top: {
                                    style: BorderStyle.NONE,
                                    color: "white",
                                    size: 0
                                },
                            },
                            width: {
                                size: 14,
                                type: WidthType.PERCENTAGE
                            }
                        }),
                    ]
                }),

                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Fecha de tratamiento',
                                            font: 'Arial',
                                            size: 20,
                                        })
                                    ],
                                    spacing: { before: 100, after: 100 }
                                })
                            ],
                            borders: {
                                top: {
                                    style: BorderStyle.NONE,
                                    color: "white",
                                    size: 0
                                },
                            },
                            width: {
                                size: 14,
                                type: WidthType.PERCENTAGE
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Nombre comercial del producto',
                                            font: 'Arial',
                                            size: 20,
                                        })
                                    ],
                                    spacing: { before: 100, after: 100 }
                                })
                            ],
                            borders: {
                                top: {
                                    style: BorderStyle.NONE,
                                    color: "white",
                                    size: 0
                                },
                            },
                            width: {
                                size: 15,
                                type: WidthType.PERCENTAGE
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Nombre del ingrediente activo',
                                            font: 'Arial',
                                            size: 20,
                                        })
                                    ],
                                    spacing: { before: 100, after: 100 }
                                })
                            ],
                            borders: {
                                top: {
                                    style: BorderStyle.NONE,
                                    color: "white",
                                    size: 0
                                },
                            },
                            width: {
                                size: 15,
                                type: WidthType.PERCENTAGE
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '% de cobre',
                                            font: 'Arial',
                                            size: 20,
                                        })
                                    ],
                                    spacing: { before: 100, after: 100 }
                                })
                            ],
                            borders: {
                                top: {
                                    style: BorderStyle.NONE,
                                    color: "white",
                                    size: 0
                                },
                            },
                            width: {
                                size: 14,
                                type: WidthType.PERCENTAGE
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Aplicación de producto (kg)',
                                            font: 'Arial',
                                            size: 20,
                                        })
                                    ],
                                    spacing: { before: 100, after: 100 }
                                })
                            ],
                            borders: {
                                top: {
                                    style: BorderStyle.NONE,
                                    color: "white",
                                    size: 0
                                },
                            },
                            width: {
                                size: 14,
                                type: WidthType.PERCENTAGE
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Superficie tratada (ha)',
                                            font: 'Arial',
                                            size: 20,
                                        })
                                    ],
                                    spacing: { before: 100, after: 100 }
                                })
                            ],
                            borders: {
                                top: {
                                    style: BorderStyle.NONE,
                                    color: "white",
                                    size: 0
                                },
                            },
                            width: {
                                size: 14,
                                type: WidthType.PERCENTAGE
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Total de cobre aplicado (kg/ha)',
                                            font: 'Arial',
                                            size: 20,
                                        })
                                    ],
                                    spacing: { before: 100, after: 100 }
                                })
                            ],
                            borders: {
                                top: {
                                    style: BorderStyle.NONE,
                                    color: "white",
                                    size: 0
                                },
                            },
                            width: {
                                size: 14,
                                type: WidthType.PERCENTAGE
                            }
                        }),
                    ]
                }),
            ],
            margins: {
                left: 70,
                right: 70
            },
            width: {
                size: 100,
                type: WidthType.PERCENTAGE,
            },
            columnWidths: [14, 15, 15, 14, 14, 14, 14]
        })

        let totalCobre = 0
        const listaProductos = element.calculo_cobre_productos
        listaProductos.forEach(product => {
            const porcentaje = Number(product.calculo_cobre_productos_porcentaje_cobre)
            const aplicacion_producto = Number(product.calculo_cobre_productos_aplicacion)
            const superficie =Number(product.calculo_cobre_productos_superficie)
            const totalByProduct = ((porcentaje / 100) * aplicacion_producto) / superficie
            totalCobre += totalByProduct

            table.addChildElement(
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: `${moment(product.calculo_cobre_productos_fecha).format('DD/MM/YYYY')}`,
                                            font: 'Arial',
                                            size: 20,
                                        })
                                    ],
                                    spacing: { before: 100, after: 100 }
                                })
                            ],
                            borders: {
                                top: {
                                    style: BorderStyle.NONE,
                                    color: "white",
                                    size: 0
                                },
                            },
                            width: {
                                size: 14,
                                type: WidthType.PERCENTAGE
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: `${product.calculo_cobre_productos_nombre_producto}`,
                                            font: 'Arial',
                                            size: 20,
                                        })
                                    ],
                                    spacing: { before: 100, after: 100 }
                                })
                            ],
                            borders: {
                                top: {
                                    style: BorderStyle.NONE,
                                    color: "white",
                                    size: 0
                                },
                            },
                            width: {
                                size: 15,
                                type: WidthType.PERCENTAGE
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: `${product.calculo_cobre_productos_nombre_ingrediente}`,
                                            font: 'Arial',
                                            size: 20,
                                        })
                                    ],
                                    spacing: { before: 100, after: 100 }
                                })
                            ],
                            borders: {
                                top: {
                                    style: BorderStyle.NONE,
                                    color: "white",
                                    size: 0
                                },
                            },
                            width: {
                                size: 15,
                                type: WidthType.PERCENTAGE
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: `${Number(porcentaje).toLocaleString('en-US', { minimumFractionDigits: 6, maximumFractionDigits: 6 })}%`,
                                            font: 'Arial',
                                            size: 20,
                                        })
                                    ],
                                    spacing: { before: 100, after: 100 }
                                })
                            ],
                            borders: {
                                top: {
                                    style: BorderStyle.NONE,
                                    color: "white",
                                    size: 0
                                },
                            },
                            width: {
                                size: 14,
                                type: WidthType.PERCENTAGE
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: `${Number(aplicacion_producto).toLocaleString('en-US', { minimumFractionDigits: 6, maximumFractionDigits: 6 })}`,
                                            font: 'Arial',
                                            size: 20,
                                        })
                                    ],
                                    spacing: { before: 100, after: 100 }
                                })
                            ],
                            borders: {
                                top: {
                                    style: BorderStyle.NONE,
                                    color: "white",
                                    size: 0
                                },
                            },
                            width: {
                                size: 14,
                                type: WidthType.PERCENTAGE
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: `${Number(superficie).toLocaleString('en-US', { minimumFractionDigits: 6, maximumFractionDigits: 6 })}`,
                                            font: 'Arial',
                                            size: 20,
                                        })
                                    ],
                                    spacing: { before: 100, after: 100 }
                                })
                            ],
                            borders: {
                                top: {
                                    style: BorderStyle.NONE,
                                    color: "white",
                                    size: 0
                                },
                            },
                            width: {
                                size: 14,
                                type: WidthType.PERCENTAGE
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: `${Number(totalByProduct).toLocaleString('en-US', { minimumFractionDigits: 6, maximumFractionDigits: 6 })}`,
                                            font: 'Arial',
                                            size: 20,
                                        })
                                    ],
                                    spacing: { before: 100, after: 100 }
                                })
                            ],
                            borders: {
                                top: {
                                    style: BorderStyle.NONE,
                                    color: "white",
                                    size: 0
                                },
                            },
                            width: {
                                size: 14,
                                type: WidthType.PERCENTAGE
                            }
                        }),
                    ]
                })
            )
        })

        table.addChildElement(
            new TableRow({
                children: [
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.CENTER,
                                children: [
                                    new TextRun({
                                        text: '',
                                        font: 'Arial',
                                        size: 20,
                                    })
                                ],
                                spacing: { before: 100, after: 100 }
                            })
                        ],
                        borders: {
                            top: {
                                style: BorderStyle.NONE,
                                color: "white",
                                size: 0
                            },
                            bottom: {
                                style: BorderStyle.NONE,
                                color: "white",
                                size: 0
                            },
                            left: {
                                style: BorderStyle.NONE,
                                color: "white",
                                size: 0
                            },
                        },
                        width: {
                            size: 44,
                            type: WidthType.PERCENTAGE
                        },
                        columnSpan: 3
                    }),
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.CENTER,
                                children: [
                                    new TextRun({
                                        text: 'Total de cobre aplicado (kg/ha)',
                                        font: 'Arial',
                                        size: 20,
                                    })
                                ],
                                spacing: { before: 100, after: 100 }
                            })
                        ],
                        borders: {
                            top: {
                                style: BorderStyle.NONE,
                                color: "white",
                                size: 0
                            },
                        },
                        width: {
                            size: 42,
                            type: WidthType.PERCENTAGE
                        },
                        columnSpan: 3
                    }),
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.CENTER,
                                children: [
                                    new TextRun({
                                        text: `${Number(totalCobre).toLocaleString('en-US', { minimumFractionDigits: 6, maximumFractionDigits: 6 })}`,
                                        font: 'Arial',
                                        size: 20,
                                    })
                                ],
                                spacing: { before: 100, after: 100 }
                            })
                        ],
                        borders: {
                            top: {
                                style: BorderStyle.NONE,
                                color: "white",
                                size: 0
                            },
                        },
                        width: {
                            size: 14,
                            type: WidthType.PERCENTAGE
                        },
                        columnSpan: 1
                    }),
                ]
            }),
        )

        return table
    }


    getValue(submission, name) {
        var value = null
        for (const i in submission) {
            const element = submission[i]
            if (element.name === name) {
                value = element.value
            }
        }
        if (value) return value
        else return ''
    }

    getValueList(submission, name) {
        var value = null
        for (const i in submission) {
            const element = submission[i]
            if (element.name === name) {
                value = element.value
            }
        }
        if (value) return value
        else return []
    }
    // END MTO_FINS_08
}