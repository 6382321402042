import { ControlCommunicationService } from './../../../services/control-communication.service';
import { FormBuilderComponent } from './../../../form-builder.component'
import { CataloguesService } from './../../../../core/services/catalogues.service';
import { UnknownParams, ControlElement } from './../../../interfaces/form.interface';
import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';

import { Scopes } from '@app/utils/scopes'

@Component({
    selector: 'multiselect-element',
    templateUrl: './multi-select.component.html'
})
export class MultiSelectComponent implements OnInit {

    attributes: UnknownParams = {}
    validations: UnknownParams = {}
    options: {
        name: String,
        value: any
    }[] = []

    @Input() data: ControlElement
    @Input() form: FormGroup

    scopesVariants = []

    // inject parent FormBuilder Component in order to access FormGroup
    constructor(
        private parentControl: FormBuilderComponent,
        private communicationService: ControlCommunicationService,
        private route: ActivatedRoute,
        private cataloguesService: CataloguesService,
        private scopes: Scopes,
    ) {
        this.scopesVariants = this.scopes.getAllScopesVariants()

        this.communicationService.changeEmmited$.subscribe(async msg => {
            if (msg.identifier === 'scopes') {
                const variants = msg.scopes
                this.options = variants.map(variant => {
                    const scope = this.scopesVariants.find(scope => scope.variant === parseInt(variant))
                    return {
                        name: scope.scopeName,
                        value: scope.scope
                    }
                })
            } else if(msg.identifier === 'analisis') {
                this.form.patchValue({
                    informe_submuestra_1_analisis: []
                })

                this.options = msg.analisis
            }
        })
    }

    ngOnInit(): void {
        // asign parent formGroup to local formGroup
        if (!this.form) { this.form = this.parentControl.formGroup }
        // iterate and access component attributes
        for (var i in this.data.attributes) {
            const attribute = this.data.attributes[i]
            this.attributes[attribute.name] = attribute.value
        }
        for (var i in this.data.validations) {
            const validation = this.data.validations[i]
            this.validations[validation.name] = validation.value
        }

        const recordId = this.route.snapshot.params['recordId'];
        if(recordId) {
            const tmpVariants = localStorage.getItem("variant"+recordId)
            const variants = tmpVariants.split(",")
            this.options = variants.map(variant => {
                const scope = this.scopesVariants.find(scope => scope.variant === parseInt(variant))
                return {
                    name: scope.scopeName,
                    value: scope.scope
                }
            })
        } else if(this.attributes.name === "informe_submuestra_1_analisis" && this.form.value.informe_submuestra_1_laboratorio !== '') {
            var laboratorio = this.form.value.informe_submuestra_1_laboratorio

            this.cataloguesService.getOnlyLaboratories().pipe(first()).subscribe(data => {
                if(data.success) {
                  for (var i in data.data) {
                    const elem = data.data[i]
                    if(elem.Name === laboratorio) {
                      var tests = JSON.parse(elem.Tests)
                      this.options = []
                      for(var i in tests) {
                        const option = tests[i]
                        this.options.push({
                          name: option.name,
                          value: option.name
                        })
                      }

                      break
                    }
                  }
                }
              }, error => {
                console.log("Error al consultar analisis de laboratorio " + laboratorio)
              })
        }
        
        this.form.addControl(this.attributes.name, new FormControl(""))
    }

    selectedOption = [];
}
